exports.components = {
  "component---src-pages-navigation-tsx": () => import("./../../../src/pages/navigation.tsx" /* webpackChunkName: "component---src-pages-navigation-tsx" */),
  "component---src-pages-news-and-insights-tsx": () => import("./../../../src/pages/news-and-insights.tsx" /* webpackChunkName: "component---src-pages-news-and-insights-tsx" */),
  "component---src-templates-insight-page-tsx": () => import("./../../../src/templates/InsightPage.tsx" /* webpackChunkName: "component---src-templates-insight-page-tsx" */),
  "component---src-templates-page-tsx": () => import("./../../../src/templates/Page.tsx" /* webpackChunkName: "component---src-templates-page-tsx" */),
  "component---src-templates-sector-page-tsx": () => import("./../../../src/templates/SectorPage.tsx" /* webpackChunkName: "component---src-templates-sector-page-tsx" */),
  "component---src-templates-service-page-tsx": () => import("./../../../src/templates/ServicePage.tsx" /* webpackChunkName: "component---src-templates-service-page-tsx" */),
  "component---src-templates-spotlight-page-tsx": () => import("./../../../src/templates/SpotlightPage.tsx" /* webpackChunkName: "component---src-templates-spotlight-page-tsx" */)
}

