import { createGlobalStyle } from 'styled-components';
import media, { setBreakPoints } from 'css-in-js-media';

export const BREAK_POINTS = {
  smallPhone: 360,
  phone: 520,
  tablet: 768,
  desktop: 1200,
  largeDesktop: 1440,
};

setBreakPoints(BREAK_POINTS);

export const GlobalStyle = createGlobalStyle`

:root {
  // Colours
  // - Brand colours
  --colour-dark-blue: #010017;
  --colour-turquoise: #1acebc;
  --colour-blue: #00ACB5;
  --colour-grey: #F2F3F3;
  --colour-light-grey: #E5E7E7;
  --colour-white: #ffffff;
  --colour-black: #000F14;
  --colour-dark-turquoise: #5A7882;
  --colour-dark-grey: #46555F;
  --colour-label-grey: #9D9D9D;

  --colour-error: #cc0f0f;
  --colour-success: #8dc434;
  --colour-text-color: var(--colour-dark-blue) ;
  --colour-link: var(--colour-turquoise);
  --colour-disabled-color: var(--colour-grey);

  // Layout
  ///////////////////////////////////
  --content-max-width: 1380px;

  --layout-gap: 20px;

  --layout-grid--mobile: repeat(4, 1fr);
  --layout-grid--tablet: repeat(8, 1fr);
  --layout-grid--desktop: repeat(12, 1fr);

  --layout-grid: var(--layout-grid--mobile);

  ${media('>tablet')} {
    --layout-gap: 25px;
    --layout-grid: var(--layout-grid--tablet)
  }

  ${media('>desktop')} {
    --layout-gap: 25px;
    --layout-grid: var(--layout-grid--desktop)
  }

  // Type
  --light-body-font-family: "Alliance 1", sans-serif;
  --medium-body-font-family: "Alliance 1", sans-serif;

  --light-title-font-family: "Alliance 2", sans-serif;

  //Spacing

  --big-elements-spacing: 50px;
  --small-elements-spacing: 24px;

  --mobile-section-side-padding: 20px;
  --tablet-section-side-padding: 80px;

  --tablet-section-top-padding: 60px;

  --module-padding: var(--tablet-section-top-padding) var(--mobile-section-side-padding);

  ${media('>phone')} {
    --module-padding: var(--tablet-section-top-padding) var(--tablet-section-side-padding);
  }

  --section-side-padding-new: 25px;

  --mobile-section-top-padding-new: 40px;
  --tablet-section-top-padding-new: 60px;
  --desktop-section-top-padding-new: 120px;

  --module-padding-new: var(--mobile-section-top-padding-new) var(--section-side-padding-new);

  ${media('>phone')} {
    --module-padding-new: var(--tablet-section-top-padding-new) var(--section-side-padding-new);
  }

  ${media('>desktop')} {
    --module-padding-new: var(--desktop-section-top-padding-new) var(--section-side-padding-new);
  }

   @keyframes slide1 {
   0%,
   100% {
     transform: translate(0, 0);
   }
   50% {
     transform: translate(-10px, 0);
   }
 }
}


* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  padding: 0 !important;
}

::-moz-selection {
  /* Code for Firefox */
  color: white;
  background: black;
}

::selection {
  color: white;
  background: black;
}

h1 {
  font-family: var(--light-title-font-family);
  font-size: 48px;
  font-weight: 300;
  line-height: 1em;
  margin: 0;
  -webkit-font-smoothing: antialiased;

  ${media('>tablet')} {
    font-size: 86px;
  }

  ${media('>desktop')} {
    font-size: 115px;
  }
}

h2 {
  font-family: var(--light-title-font-family);
  font-size: 24px;
  font-weight: 300;
  line-height: 1.25em;
  margin: 0;
  -webkit-font-smoothing: antialiased;

  ${media('>phone')} {
    font-size: 36px;
  }
}

h3 {
  font-family: var(--light-title-font-family);
  font-size: 24px;
  font-weight: 300;
  line-height: 1.25em;
  margin: 0;
  -webkit-font-smoothing: antialiased;

  ${media('>tablet')} {
    font-size: 36px;
  }
}

h2.link {
  font-family: var(--light-title-font-family);
  font-size: 36px;
  font-weight: 300;
  line-height: 1.25em;
  margin: 0;
  color: var(--link);
  -webkit-font-smoothing: antialiased;
}

h4 {
 font-family: var(--medium-body-font-family);
 font-size: 14px;
 font-weight: 500;
 text-transform: uppercase;
 letter-spacing: 1px;
 -webkit-font-smoothing: antialiased;
}

h2,
h3,
h4,
blockquote {
    margin-top: 1.2em;
    margin-bottom: 0.5em;
    
    ${media('>tablet')} {
      margin-top: 1.4em;
      margin-bottom: 0.65em;
    }
  }

h2:first-child,
h3:first-child,
h4:first-child,
p:first-child, 
blockquote:first-child {
    margin-top: 0;
  }

h2:last-child,
h3:last-child,
h4:last-child,
blockquote:last-child {
    margin-bottom: 0;
  }

  h2 + h2,
  h3 + h3,
  h4 + h4,
  blockquote + blockquote {
    margin-top: -0.5em;
  }

p, span, li {
  font-family: var(--light-body-font-family);
  font-size: 14px;
  font-weight: 300;
  line-height: 1.5em;
  margin: 0; 

  ${media('>phone')} {
    font-size: 16px;
  }
}

p {
    margin-bottom: 1em;
  
  &:last-child {
    margin-bottom: 0;
  }
}

p:empty {
  margin-top: 0;
  height: 24px;

  & + & {
    margin-top: 0;
  }
}

ul, ol {
  padding-left: 1em;
  margin: 30px 0;
}

li + li {
  margin-top: 16px;
}



a {
  font-family: var(--light-body-font-family);
  font-size: 16px;
  font-weight: 300;
  line-height: 1.5em;
  color: var(--text-color) ;
  margin: 0;

  &:hover {
    color: var(--colour-turquoise);
  }
 }
`;
